@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('./reset.css');

:root {
  font-family: 'Poppins', sans-serif;


}

@media only screen and (max-width: 480px) {
  :root {
    font-size: 14px;
  }
}