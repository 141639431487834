.container {
    position: relative;
    display: inline;
}

input:focus+.suggestions,
.suggestions:hover {
    display: block;
}

.suggestions {
    display: none;
    max-height: 15rem;
    width: 100%;
    background-color: white;
    position: absolute;
    bottom: -1;
    border: 1px solid gray;
    overflow-y: scroll;
}

.suggestion {
    height: 1rem;
    font-size: 1rem;
    line-height: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    user-select: none;
}

.suggestion:hover {
    background-color: lightgray;
}